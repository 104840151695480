import React, {Component} from 'react';
import {PATHS} from "../../../constants";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {closeModal} from "../../../storeManager/modal/actions";
import {connect} from "react-redux";
import '../temporarily-suspend.css'
import errorimg from '../../../assets/images/error.svg';
import CustomButton from '../../Common/Button'

class ConfirmationPopup extends Component {

    redirectToHome = () => {
        const {history, closeModal} = this.props;
        closeModal();
        history.push(PATHS.HOME)
    };

    render() {
      const { modalParameters: { message, forgotpassData, reDirectLinkHide, redirectLoginPage, showOkCloseButton, Dashboard, getBoxIdList,showButton, redirectModal,packModifyText}, closeModal } = this.props;
        if(forgotpassData){
            return (

                <div className="center ptb30">
                    <div className="b2b-modal-header pb35">
                        <div className="gradient-text pad20">
                            {forgotpassData.message}
                        </div>
                        {message ==='SUCCESS' && (forgotpassData.rmn || forgotpassData.emailId) && <div className="rmn-field rmn-forgetpassword">
                       <span className='rmn-field-title mb10 mr5'> Mobile number: </span> 
                       <span className={!forgotpassData.mobileFlag ? 'error-msg' : ''}>
                       {!forgotpassData.mobileFlag && <img src={errorimg} className="margin5" width="15px" alt=""/>}
                        {forgotpassData.rmn} </span>
                       <br/>
                       <span className='rmn-field-title mr5'>Email ID: </span> 
                       <span className={!forgotpassData.emailFlag ? 'error-msg' : ''}>
                        {!forgotpassData.emailFlag && <img src={errorimg} className="margin5" width="15px"  alt=""/>}
                        {forgotpassData.emailId}</span>
                        </div>}
                    </div>
                    {
                        reDirectLinkHide ?  null : (
                          <button className="button fill" onClick={() => this.redirectToHome()}>
                            {
                              redirectLoginPage ? 'Go to Login' : 'Go to Dashboard'
                            }
                          </button>
                        )
                    }
                    {
                        showOkCloseButton ? (
                          <button className="button fill" onClick={() => closeModal()}>
                            {packModifyText ? 'Ok, Got it' : 'Ok'}
                          </button>
                        ) :  null
                    }
                    {
                        showButton ? (
                            <button className="button fill" onClick={() => redirectModal()}>
                            Ok, Got it
                            </button>
                        ) :  null
                    }
                </div>
            );
        } 

        return (

            <div className="text-center ptb30">
                <div className="b2b-modal-header pb35">
                    <div className="gradient-text">
                        {message}
                    </div>
                    {/* {(rmn || emailId) && (mobileFlag || emailFlag) && <div className="rmn-field">
                   <span className='rmn-field-title'> Mobile number: </span> 
                   {rmn && mobileFlag ? rmn : errorMessage} 
                   <br/>
                   <span className='rmn-field-title'>Email ID: </span> 
                   {emailId && emailFlag ? emailId : errorMessage}
                    </div>} */}
                </div>
                {
                    reDirectLinkHide ?  null : (
                      <button className="button fill" onClick={() => this.redirectToHome()}>
                        {
                          redirectLoginPage ? 'Go to Login' : 'Go to Dashboard'
                        }
                      </button>
                    )
                }
                {
                    showOkCloseButton ? (
                <CustomButton
                  content= {packModifyText ? "Ok, Got it" : "Ok"}
                  classes="primary ok-button"
                  config={{ type: 'button' }}
                  onClick={() => closeModal()}
                />
                    ) :  null
                }
                 {
                    showButton ? (
                <CustomButton
                  content="Ok, Got it"
                  classes="primary ok-button"
                  config={{ type: 'button' }}
                  onClick={() => redirectModal()}
                />
                    ) :  null
                }
                {
                    Dashboard ? (
                        <>
                        <div className='confirmation-button'>
                             <button className="btn btn-secondary sec-btn" onClick={() => closeModal()}>
                                    Cancel
                             </button>
                             <button className="btn btn-primary" onClick={() => getBoxIdList()}>
                                    Retry
                             </button>
                        </div>
                    </>
                    ) : null
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(ConfirmationPopup));
